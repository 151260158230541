import {
    createContext,
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from 'react';
import SceneView from '@arcgis/core/views/SceneView';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';

import { DefaultMapContextMenu } from 'components/mapContextMenu/DefaultMapContextMenu';
import { GoToTarget3DOptions } from 'types/GoToTarget3DOptions';

type ContextMenuComponent = (props: { view: SceneView | null }) => ReactElement;

export interface MapContextData {
    sketchViewModel: SketchViewModel | null;
    setSketchViewModel: Dispatch<SetStateAction<SketchViewModel | null>>;
    showLegend: boolean;
    setShowLegend: Dispatch<SetStateAction<boolean>>;
    showBuildingExcluding: boolean;
    setShowBuildingExcluding: Dispatch<SetStateAction<boolean>>;
    showMarketViewLegend: boolean;
    setShowMarketViewLegend: Dispatch<SetStateAction<boolean>>;
    showPolygonEditorModal: string;
    setShowPolygonEditorModal: Dispatch<SetStateAction<string>>;
    goTo: GoToTarget3DOptions | null;
    setGoTo: Dispatch<GoToTarget3DOptions>;
    mapContextMenuPosition: [x: number, y: number];

    showMapContextMenu(
        position: [x: number, y: number],
        contextMenu?: (props: { view: SceneView | null; libraryKey?: string }) => ReactElement
    ): void;

    hideMapContextMenu(): void;

    CurrentContextMenu: ContextMenuComponent | undefined;
    enableKeyboardNavigation: boolean;
    setEnableKeyboardNavigation: Dispatch<SetStateAction<boolean>>;
}

const MapContext = createContext<MapContextData>({} as MapContextData);

const MapProvider = ({ children }: { children: ReactNode }) => {
    const [goTo, setGoTo] = useState<GoToTarget3DOptions | null>(null);
    const [sketchViewModel, setSketchViewModel] = useState<SketchViewModel | null>(null);
    const [showLegend, setShowLegend] = useState<boolean>(false);
    const [showBuildingExcluding, setShowBuildingExcluding] = useState<boolean>(false);
    const [showMarketViewLegend, setShowMarketViewLegend] = useState<boolean>(false);
    const [showPolygonEditorModal, setShowPolygonEditorModal] = useState<string>('none');
    const [mapContextMenuPosition, setMapContextMenuPosition] = useState<[x: number, y: number]>([
        0, 0,
    ]);
    const [CurrentContextMenu, setCurrentContextMenu] = useState<
        ((props: { view: SceneView | null; libraryKey?: string }) => ReactElement) | undefined
    >();

    const [enableKeyboardNavigation, setEnableKeyboardNavigation] = useState<boolean>(true);

    const showMapContextMenu: MapContextData['showMapContextMenu'] = useCallback(
        (position, contextMenu = DefaultMapContextMenu) => {
            setMapContextMenuPosition(position);
            setCurrentContextMenu(() => contextMenu);
        },
        []
    );

    const hideMapContextMenu = useCallback(() => {
        setCurrentContextMenu(undefined);
    }, []);

    return (
        <MapContext.Provider
            value={{
                goTo,
                setGoTo,
                sketchViewModel,
                setSketchViewModel,
                showLegend,
                setShowLegend,
                showBuildingExcluding,
                setShowBuildingExcluding,
                showMarketViewLegend,
                setShowMarketViewLegend,
                showPolygonEditorModal,
                setShowPolygonEditorModal,
                mapContextMenuPosition,
                showMapContextMenu,
                hideMapContextMenu,
                CurrentContextMenu,
                enableKeyboardNavigation,
                setEnableKeyboardNavigation,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};

function useMap(): MapContextData {
    const context = useContext(MapContext);

    return context;
}

export { MapProvider, useMap };
