import Color from '@arcgis/core/Color';
import Layer from '@arcgis/core/layers/Layer';
import MediaLayer from '@arcgis/core/layers/MediaLayer';
import ExtentAndRotationGeoreference from '@arcgis/core/layers/support/ExtentAndRotationGeoreference';
import ImageElement from '@arcgis/core/layers/support/ImageElement';
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import SolidEdges3D from '@arcgis/core/symbols/edges/SolidEdges3D';
import FillSymbol3DLayer from '@arcgis/core/symbols/FillSymbol3DLayer';
import LineSymbol3D from '@arcgis/core/symbols/LineSymbol3D';
import LineSymbol3DLayer from '@arcgis/core/symbols/LineSymbol3DLayer';
import MeshSymbol3D from '@arcgis/core/symbols/MeshSymbol3D';
import LineStylePattern3D from '@arcgis/core/symbols/patterns/LineStylePattern3D';
import PointSymbol3D from '@arcgis/core/symbols/PointSymbol3D';
import PolygonSymbol3D from '@arcgis/core/symbols/PolygonSymbol3D';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';

import KmlLayerMetadata, {
    LayerBounds,
    LayerPlacemarkStyles,
    MarketLayerSceneLayer,
} from 'types/Layers/KmlLayerMetadata';
import { createExtentFromKmlBounds } from 'utils/esriUtils';
import { getCloudinaryUrl } from 'utils/imageUtils';
import { createKmlFeatureLayer, createKmlSceneLayer } from './kmlLayerHelper';

const blackbirdBuildingRedColor = '#FF0000';
const defaultIconSize = 32; // in px
const defaultPolygonSymbol = new PolygonSymbol3D({
    symbolLayers: [
        new FillSymbol3DLayer({
            material: {
                color: blackbirdBuildingRedColor,
            },
        }),
    ],
});

const defaultMeshSymbol3D = new MeshSymbol3D({
    symbolLayers: [
        new FillSymbol3DLayer({
            material: {
                color: blackbirdBuildingRedColor,
            },
        }),
    ],
});

const defaultLineSymbol3D = new LineSymbol3D({
    symbolLayers: [
        new LineSymbol3DLayer({
            material: {
                color: blackbirdBuildingRedColor,
            },
            pattern: new LineStylePattern3D({
                style: 'solid',
            }),
        }),
    ],
});

export const createKmlMapLayers = (layerDefinition: KmlLayerMetadata) => {
    const { styles, sceneLayers } = layerDefinition;
    if (!sceneLayers?.length) return;
    const resultLayers = [] as Layer[];
    for (let i = 0; i < sceneLayers.length; i++) {
        const layer = sceneLayers[i];
        const renderer = createRendererByGeometryType(layer.layerType, styles);
        if (renderer) {
            const sceneLayer = createKmlMapLayerByGeometryType(layer);
            sceneLayer.renderer = renderer;
            resultLayers.push(sceneLayer);
        }
    }
    return resultLayers;
};

const createIconStyleUniqueRenderer = (styles: Record<number, LayerPlacemarkStyles>) => {
    const _uniqueValueInfos: UniqueValueInfo[] = Object.entries(styles).map(([symbolID, style]) => {
        const iconSize = Math.floor(
            style.iconStyle?.scale ? defaultIconSize * style.iconStyle.scale : defaultIconSize
        );

        return new UniqueValueInfo({
            value: parseInt(symbolID),
            symbol: new PointSymbol3D({
                symbolLayers: [
                    {
                        type: 'icon',
                        resource: {
                            href:
                                style.iconStyle?.icon.href &&
                                getCloudinaryUrl(
                                    style.iconStyle?.icon.href,
                                    '',
                                    false,
                                    iconSize,
                                    iconSize
                                ),
                        },
                        size: `${iconSize}px`,
                    },
                ],
            }),
        });
    });
    return new UniqueValueRenderer({
        field: 'SymbolID',
        defaultSymbol: new SimpleMarkerSymbol({
            color: blackbirdBuildingRedColor,
        }),
        uniqueValueInfos: _uniqueValueInfos,
    });
};

function numToColor(value: number | undefined) {
    let color = new Color({ r: 255, g: 255, b: 255, a: 0 });
    if (value) {
        const buffer = new ArrayBuffer(4);
        const int32Array = new Uint32Array(buffer);
        const int8Array = new Uint8Array(buffer);
        int32Array[0] = value;
        color = new Color({
            r: int8Array[0],
            g: int8Array[1],
            b: int8Array[2],
            a: int8Array[3] / 255,
        });
    }
    return color;
}

const createMultipatchStyleUniqueRenderer = (styles: Record<number, LayerPlacemarkStyles>) => {
    const uniqueValueInfos: UniqueValueInfo[] = Object.entries(styles).map(([symbolID, style]) => {
        return new UniqueValueInfo({
            value: parseInt(symbolID),
            symbol: new MeshSymbol3D({
                symbolLayers: [
                    new FillSymbol3DLayer({
                        material: {
                            color: numToColor(style.polyStyle?.color),
                        },
                        edges: new SolidEdges3D({
                            color: numToColor(style.lineStyle?.color),
                            size: style.lineStyle?.width && `${style.lineStyle.width}px`,
                        }),
                    }),
                ],
            }),
        });
    });
    return new UniqueValueRenderer({
        field: 'SymbolID',
        defaultSymbol: defaultMeshSymbol3D,
        uniqueValueInfos: uniqueValueInfos,
    });
};

const createPolygonStyleUniqueRenderer = (styles: Record<number, LayerPlacemarkStyles>) => {
    const uniqueValueInfos: UniqueValueInfo[] = Object.entries(styles).map(([symbolID, style]) => {
        return new UniqueValueInfo({
            value: parseInt(symbolID),
            symbol: new PolygonSymbol3D({
                symbolLayers: [
                    new FillSymbol3DLayer({
                        material: {
                            color: numToColor(style.polyStyle?.color),
                        },
                        outline: {
                            color: numToColor(style.lineStyle?.color),
                            size: style.lineStyle?.width,
                        },
                    }),
                ],
            }),
        });
    });
    return new UniqueValueRenderer({
        field: 'SymbolID',
        defaultSymbol: defaultPolygonSymbol,
        uniqueValueInfos: uniqueValueInfos,
    });
};

const createPolylineStyleUniqueRenderer = (styles: Record<number, LayerPlacemarkStyles>) => {
    const uniqueValueInfos: UniqueValueInfo[] = Object.entries(styles).map(([symbolID, style]) => {
        return new UniqueValueInfo({
            value: parseInt(symbolID),
            symbol: new LineSymbol3D({
                symbolLayers: [
                    new LineSymbol3DLayer({
                        material: {
                            color: numToColor(style.lineStyle?.color),
                        },
                        pattern: new LineStylePattern3D({
                            style: 'solid',
                        }),
                    }),
                ],
            }),
        });
    });
    return new UniqueValueRenderer({
        field: 'SymbolID',
        defaultSymbol: defaultLineSymbol3D,
        uniqueValueInfos: uniqueValueInfos,
    });
};

const createRendererByGeometryType = (
    geometryType: string,
    styles: Record<number, LayerPlacemarkStyles>
) => {
    switch (geometryType) {
        case 'Point':
            return createIconStyleUniqueRenderer(styles);
        case 'Multipatch':
            return createMultipatchStyleUniqueRenderer(styles);
        case 'Polyline':
            return createPolylineStyleUniqueRenderer(styles);
        case 'Polygon':
            return createPolygonStyleUniqueRenderer(styles);
    }
};

const createKmlMapLayerByGeometryType = (marketLayerSceneLayer: MarketLayerSceneLayer) => {
    switch (marketLayerSceneLayer.layerType) {
        case 'Point':
        case 'Multipatch':
            return createKmlSceneLayer(marketLayerSceneLayer.sceneLayerId);
        case 'Polyline':
        case 'Polygon':
            return createKmlFeatureLayer(marketLayerSceneLayer.sceneLayerId);
    }
};

export const createGroundOverlayMediaLayer = (
    key: string,
    href: string,
    bounds: LayerBounds,
    rotation: number
) => {
    const imageElement = new ImageElement({
        image: href,
        georeference: new ExtentAndRotationGeoreference({
            extent: createExtentFromKmlBounds(bounds),
            rotation,
        }),
    });
    return new MediaLayer({
        id: key,
        source: [imageElement],
    });
};

export const getGroundOverlayStyles = (
    styleId: number,
    styles: Record<number, LayerPlacemarkStyles>
) => {
    const style = styles[styleId];
    return style['overlayStyle'];
};
