import OpacityVariable from '@arcgis/core/renderers/visualVariables/OpacityVariable';

export const createOpacityVisualVariable = () => {
    const expression =
        'var buildingStatus = $feature.BuildingStatus; ' +
        "var transparency = When(buildingStatus == 'Proposed Short-Term', 0.5, buildingStatus == 'Proposed Long-Term', 0.5, 1); " +
        'return transparency;';

    return new OpacityVariable({
        valueExpression: expression,
        stops: [
            {
                value: 0.5,
                opacity: 0.5,
            },
            {
                value: 1,
                opacity: 1,
            },
        ],
    });
};
