import Color from '@arcgis/core/Color';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Layer from '@arcgis/core/layers/Layer';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import UniqueValue from '@arcgis/core/renderers/support/UniqueValue';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import Font from '@arcgis/core/symbols/Font';
import PolygonSymbol3D from '@arcgis/core/symbols/PolygonSymbol3D';
import TextSymbol3DLayer from '@arcgis/core/symbols/TextSymbol3DLayer';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import { map } from 'lodash';

import {
    HighlightFloorsProps,
    HighlightSetMetadata,
    HighlightSetProperty,
} from 'types/Layers/HighlightSetMetadata';
import LibraryLayerTreeItem, { HighlightSetTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import { PropertyResultItem } from 'types/Search/PropertySearchResultProps';
import { getOppositeShade } from 'utils/colorUtils';
import {
    buildNumberedIcon,
    createIconForMaterialSymbols,
    getPinSizeForDisplay,
    renderNumberedIcon,
} from 'utils/iconBuilder/iconBuilderUtils';
import {
    addUniqueValueGroupToRenderer,
    addUniqueValuesToGroup,
    createFloorsUniqueValueGroupsRenderer,
    createMeshSymbol3D,
    createMultipleUniqueValueClass,
    createUniqueValueGroup,
} from './polygonLayerHelper';

const iconCache = new Map();

export const defaultPinColor = 'rgb(64,121,141)';
export const defaultPinType = 'generic';
export const defaultPinIcon = 'location_on';
export const defaultPinSize = 40;
export const defaultPinHeight = 50;
export const defaultOpacity = 1;
export const defaultHighlightOptions = {
    haloColor: new Color('#00FFFF'),
    color: new Color('#D3D3D3'),
};
export const defaultCsvPinColor = 'rgb(102,117,121)';

export interface HighlightSetStyleProperties {
    pinColor: string;
    pinType: string;
    pinIcon: string;
    pinHeight: number;
    pinSize: number;
    opacity: number;
    enabledPinIcon: boolean;
    enabledPinColor: boolean;
}

export const defaultHighlightSetStyles = (
    type?: string,
    pinType = defaultPinType
): HighlightSetStyleProperties => ({
    pinColor: type === 'csv' ? defaultCsvPinColor : defaultPinColor,
    pinType,
    pinIcon: defaultPinIcon,
    pinHeight: defaultPinHeight,
    pinSize: defaultPinSize,
    opacity: defaultOpacity,
    enabledPinIcon: true,
    enabledPinColor: true,
});

export const HighlightSetType = {
    BUILDING: 'building',
    FLOOR: 'floor',
};

export const getHighlightPinIcon = async (
    styleProperties: HighlightSetStyleProperties,
    index?: number
) => {
    const keyItems = [styleProperties.pinIcon, styleProperties.pinSize, styleProperties.pinColor];
    if (typeof index !== 'undefined') {
        keyItems.push(index);
    }
    const key = keyItems.join('-');

    if (!iconCache.has(key)) {
        const icon = await createHighlightPinIcon(styleProperties, index ?? 1);
        if (icon) iconCache.set(key, icon instanceof HTMLCanvasElement ? icon.toDataURL() : icon);
    }
    return iconCache.get(key);
};

export const createHighlightPinIcon = (
    styleProperties: HighlightSetStyleProperties,
    index: number
) => {
    const { pinIcon, pinColor, pinSize, pinType, enabledPinColor } = styleProperties;
    const foregroundColor = getOppositeShade(pinColor, 200);
    //check if pin color has been enabled. If not, it should apply the default pin color
    const color = enabledPinColor ? pinColor : undefined;
    if (pinType === 'generic')
        return createIconForMaterialSymbols(pinIcon, foregroundColor, pinSize, color);
    else if (pinType === 'numbered')
        return createNumberedIcon(pinColor, foregroundColor, index, pinSize);
};

export const createNumberedIcon = (
    pinColor: string,
    foregroundColor: string,
    index: number,
    pinSize = defaultPinSize
) => {
    const numberedIcon = renderNumberedIcon(
        {
            type: 'numbered',
            numberingType: 'arabic',
            name: 'Numbered',
            font: 'Source Sans Pro',
        },
        index,
        8
    );
    return buildNumberedIcon(
        numberedIcon,
        pinColor,
        foregroundColor,
        getPinSizeForDisplay('numbered', pinSize)
    );
};

export const addPointsToGraphics = (properties: HighlightSetProperty[]) => {
    const pinGraphics = [] as Graphic[];
    properties.forEach((property: HighlightSetProperty) => {
        if (property?.geolocation) {
            pinGraphics.push(
                new Graphic({
                    geometry: new Point(property.geolocation),
                    attributes: { PropId: property.propertyId, height: property.height },
                })
            );
        }
    });
    return pinGraphics;
};

export const addBuildingToHighlightSet = (
    buildingLayer: SceneLayer,
    key: string,
    propertyIds: string[]
) => {
    const renderer = addUniqueValuesToGroup(
        buildingLayer.renderer as UniqueValueRenderer,
        key,
        propertyIds
    );
    if (renderer) buildingLayer.renderer = renderer;
};

export const addPinToHighlightSet = (
    highlightSetIconsLayer: FeatureLayer,
    key: string,
    properties: HighlightSetProperty[]
) => {
    const pinGraphics = addPointsToGraphics(properties);
    if (pinGraphics?.length) highlightSetIconsLayer.applyEdits({ addFeatures: pinGraphics });
    const renderer = addUniqueValuesToGroup(
        highlightSetIconsLayer.renderer as UniqueValueRenderer,
        key,
        map(properties, 'propertyId')
    );
    if (renderer) highlightSetIconsLayer.renderer = renderer;
};

export const getBuildingHeight = (graphics: Graphic[]) => {
    const maxHeight = Math.max(...graphics.map((graphic) => parseInt(graphic.attributes.Height)));
    return maxHeight && maxHeight >= 50 ? maxHeight : 50;
};

export const getPropertiesById = (graphics: Graphic[], properties?: PropertyResultItem[]) => {
    const matched = [] as HighlightSetProperty[];
    graphics.forEach((graphic: Graphic) => {
        const item =
            properties &&
            properties.find((property: PropertyResultItem) => {
                return property.id === graphic.attributes.PropId;
            });
        if (item)
            matched.push({
                propertyId: item.id,
                blackbirdId: graphic.attributes.BBirdId,
                buildingAddress: item.addresses[0].addressLine1,
                buildingName: item.name,
                geolocation: item.addresses[0].geolocation,
            });
    });
    return matched;
};

export const createChildren = (
    properties: HighlightSetProperty[],
    parentKey: string,
    _icon: JSX.Element
) => {
    return properties.map((property: HighlightSetProperty) => {
        const key = `PinnedProperty--${parentKey}--${property.propertyId}`;
        return {
            id: 0,
            key,
            title: property.buildingName ?? property.buildingAddress,
            itemType: 'PinnedProperties',
        } as LibraryLayerTreeItem;
    });
};

export const createHighlightSetLibraryItem = (
    key: string,
    title: string,
    titleIcon: JSX.Element,
    properties: HighlightSetProperty[],
    metadata?: HighlightSetMetadata
): LibraryLayerTreeItem => {
    return {
        id: 0,
        key,
        title,
        itemType: 'PinnedProperties',
        children: createChildren(properties, key, titleIcon),
        metadata,
    };
};

export const saveHighlightSetLibraryItem = async (libraryItem: HighlightSetTreeItem) => {
    const metadata = libraryItem.metadata;
    if (!metadata) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    const children = [] as any[];
    libraryItem.children?.forEach((item) => {
        const { key, title, checked } = item;
        children.push({
            key,
            title,
            checked,
        });
    });
    const pinnedProperties = { ...metadata, children };
    return {
        pinnedProperties: JSON.stringify(pinnedProperties),
        active: children.filter((item) => item.checked).length > 0,
    };
};

export const applyFloorFilters = (
    graphic: Graphic,
    polygonLayer: Layer,
    floorSceneLayer: Layer
) => {
    const propId = graphic.attributes.PropId;
    //hide building
    polygonLayer.set('definitionExpression', `PropId not in ('${propId}')`);

    //hide floors
    floorSceneLayer.set('definitionExpression', `PropId in ('${propId}')`);
};

export const createFloorIds = (graphic: Graphic) => {
    const floorIds = [] as UniqueValue[];
    const propId = graphic.attributes.PropId;
    const numberOfStories = graphic.attributes.NumberOfSt;
    for (let i = 1; i <= numberOfStories; i++) {
        floorIds.push(new UniqueValue({ value: propId, value2: i }));
    }
    return floorIds;
};

export const applyHighlightSetStyles = (
    view: SceneView | MapView | null,
    key: string,
    color: string,
    values: string[],
    defaultColor: Color | number[]
) => {
    const layer = view?.map.layers.find((layer) =>
        layer.id.includes('UnpinnedController')
    ) as SceneLayer;
    if (!layer) return;
    const renderer = layer.renderer as UniqueValueRenderer;
    layer.renderer = addUniqueValueGroupToRenderer(
        renderer,
        createMeshSymbol3D(color),
        key,
        values,
        defaultColor
    );
};

const createStyleGroup = (values: UniqueValue[], color: string) => {
    const defaultSymbol = createMeshSymbol3D(color);
    const defaultClass = createMultipleUniqueValueClass(values, defaultSymbol);
    return createUniqueValueGroup([defaultClass]);
};

export const applyFloorHighlightSetStyles = (
    key: string,
    color: string,
    highlightFloors: HighlightFloorsProps[],
    layer: SceneLayer
) => {
    const floorIds = highlightFloors.map((item) => {
        return new UniqueValue({
            value: item.PropId,
            value2: item.StoryNum,
        });
    });
    //create floor style based on selected colors
    const uniqueGroup = createStyleGroup(floorIds, color);
    uniqueGroup.set('key', key);

    const renderer = layer.renderer as UniqueValueRenderer;
    const groups = renderer.uniqueValueGroups.concat([uniqueGroup]);
    layer.renderer = createFloorsUniqueValueGroupsRenderer(groups);
    layer.visible = true;
};

export const updateLabel = (label: string, graphic: Graphic) => {
    const polygonSymbol3D = new PolygonSymbol3D({
        symbolLayers: [
            new TextSymbol3DLayer({
                background: {
                    color: new Color([190, 173, 156, 1]),
                },
                font: new Font({
                    decoration: 'none',
                    family: 'sans-serif',
                    size: 9,
                    style: 'normal',
                    weight: 'normal',
                }),
                halo: {
                    color: new Color([0, 0, 0, 1]),
                    size: 0,
                },
                horizontalAlignment: 'center',
                lineHeight: 1,
                material: {
                    color: new Color([0, 0, 0, 1]),
                },
                size: 9,
                text: label,
                verticalAlignment: 'baseline',
            }),
        ],
    });
    graphic.set('symbol', polygonSymbol3D);
};
