import { useRef } from 'react';

export function useInitialValue<T>(initializer: () => T): T {
    const ref = useRef<{ cell: T }>();

    if (ref.current == null) {
        ref.current = { cell: initializer() };
    }

    return ref.current.cell;
}
