import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import { subDays } from 'date-fns';

import { kmlStyleTypes } from 'constants/layer.constants';
import LayerTilesStatus from 'enums/LayerTilesStatus';
import KmlLayerMetadata, { LayerPlacemarkStyles } from 'types/Layers/KmlLayerMetadata';
import { LibraryKmlLayerSaveState } from 'types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem, { KmlLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import endpoints from 'utils/apiClient/endpoints';
import { createExtentFromKmlBounds } from 'utils/esriUtils';
import { getGroundOverlayStyles } from './kmlStylesHelper';

const MIN_SIZE_TO_BATCH = 10;

export function getLayerName(name: string) {
    return name ? name.replace(/_/g, ' ') : '';
}

export interface StyleCollectionDto {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    styles: { [styleId: string]: any };
    styleIds: { [layerId: string]: { [styleType: string]: string | number } };
}

export interface LayerConfigBlobDto {
    layerId: number;
    name: string;
    styles: StyleCollectionDto;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    hierarchy: any;
    hasExternalResources: boolean;
    hasGroundOverlayGeometries: boolean;
    tiles: Record<string, boolean>;
    size: number;
}

interface SceneLayerDto {
    sceneLayerId: string;
    layerType: string;
}

export interface LayerDetails {
    name: string;
    layerHierarchyVersion: number;
    sceneLayers: SceneLayerDto[];
}

function parseKmlHierarchy(
    layerItem: KmlLayerTreeItem,
    layerChildren: unknown,
    layerKey: string,
    layerId: number,
    styles: Record<number, LayerPlacemarkStyles>,
    getChildId: () => number
): KmlLayerTreeItem {
    if (!Array.isArray(layerChildren) || layerChildren.length === 0) {
        // No sub tree, return leaf
        layerItem.isLeaf = true;
        return layerItem;
    }

    // Sub tree exists, continue one level down
    layerItem.children = layerChildren.reduce((allLayers: LibraryLayerTreeItem[], childLayer) => {
        if (childLayer.type === 'groundoverlay') {
            const groundOverlayStyle = getGroundOverlayStyles(childLayer.styleId, styles);
            if (groundOverlayStyle) {
                childLayer.href = groundOverlayStyle.image.href;
            }
        }

        const { id, name, type, children, ...metadata } = childLayer;
        const key = `${layerKey}--${getChildId()}--${type}`;
        return [
            ...allLayers,
            parseKmlHierarchy(
                {
                    id,
                    key,
                    title: name,
                    itemType: 'KmlLayer',
                    ownerId: layerKey,
                    metadata,
                },
                children,
                layerKey,
                layerId,
                styles,
                getChildId
            ),
        ];
    }, []);

    return layerItem;
}

export const createKmlSceneLayer = (sceneLayerId: string): SceneLayer => {
    return new SceneLayer({
        portalItem: {
            id: sceneLayerId,
        },
        popupEnabled: true,
        id: sceneLayerId,
        outFields: ['*'],
        screenSizePerspectiveEnabled: true,
        elevationInfo: {
            mode: 'relative-to-scene',
        },
    });
};

export const createKmlFeatureLayer = (layerId: string): FeatureLayer => {
    return new FeatureLayer({
        portalItem: {
            id: layerId,
        },
        popupEnabled: true,
        id: layerId,
        elevationInfo: {
            mode: 'on-the-ground',
        },
    });
};

interface KmlStatusResult {
    layerTilesStatus: LayerTilesStatus;
    layerTilesStatusLastChanged: Date;
    sceneLayerStatus: LayerTilesStatus;
    sceneLayerStatusLastChanged: Date;
}

const waitUntilGenerated = async (layerId: number) => {
    let attempts = 0;

    // Keep checking the status until we reach the maximum number of attempts or the layer is generated successfully.
    while (attempts < 100) {
        // Wait for the response from the API endpoint that retrieves the layer configuration status.
        const response = await endpoints.kml.status.get({
            templateValues: { layerId },
        });

        const {
            layerTilesStatus,
            layerTilesStatusLastChanged,
            sceneLayerStatus,
            sceneLayerStatusLastChanged,
        } = (await response.json()) as KmlStatusResult;

        if (
            layerTilesStatus === LayerTilesStatus.Generated &&
            sceneLayerStatus === LayerTilesStatus.Generated
        )
            return;

        const shouldGenerateLayerTiles = shouldLayerRegenerate(
            layerTilesStatus,
            layerTilesStatusLastChanged
        );
        const shouldGenerateSceneLayer = shouldLayerRegenerate(
            sceneLayerStatus,
            sceneLayerStatusLastChanged
        );

        // Check if the layer generation failed. If so, throw an error.
        if (shouldGenerateLayerTiles || shouldGenerateSceneLayer) {
            throw new Error('Failed to generate layer');
        }

        attempts++;

        await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    // If we reach this point, it means we hit the maximum number of attempts without success. Throw an error to indicate this.
    throw new Error('Maximum attempts exceeded');
};

export const shouldLayerRegenerate = (status: LayerTilesStatus, time: Date | null): boolean => {
    if (status === LayerTilesStatus.Uploaded || status === LayerTilesStatus.Failed) {
        return true;
    }

    if (time === null) {
        return false;
    }

    const oneDayAgo = subDays(new Date(), 1);
    return (
        (status === LayerTilesStatus.Queued || status === LayerTilesStatus.Processing) &&
        time < oneDayAgo
    );
};

const waitUntilGeneratedLegacy = async (layerId: number) => {
    let attempts = 0;

    // Keep checking the status until we reach the maximum number of attempts or the layer is generated successfully.
    while (attempts < 100) {
        // Wait for the response from the API endpoint that retrieves the layer configuration status.
        const response = await endpoints.layerTiles.configurationStatus.get({
            templateValues: { layerId },
        });

        const status = await response.json();

        // Check if the layer generation failed. If so, throw an error.
        if (status.layerTilesStatus === 'Failed') {
            throw new Error('Failed to generate layer');
        } else if (status.layerTilesStatus === 'Generated') {
            // Check if the layer was generated successfully. If so, return control to the caller.
            return;
        }

        attempts++;

        await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    // If we reach this point, it means we hit the maximum number of attempts without success. Throw an error to indicate this.
    throw new Error('Maximum attempts exceeded');
};

export const getLayerDetails = async (layerId: number): Promise<LayerDetails> => {
    const response = await endpoints.layerTiles.details.get({
        templateValues: { layerId },
    });

    const result = await response.json();
    return result;
};

export const createKmlLayerItem = (layer: LibraryKmlLayerSaveState): KmlLayerTreeItem => {
    const key = `KmlLayer--${layer.id}`;
    return {
        key,
        id: layer.id,
        title: layer.name,
        itemType: 'KmlLayer',
        isLeaf: false,
        checked: layer?.active,
        metadata: {
            treeState: layer.treeState && JSON.parse(layer.treeState),
        } as KmlLayerMetadata,
        legendEnabled: true,
    } as KmlLayerTreeItem;
};

export const loadKmlData = async (libraryItem: KmlLayerTreeItem) => {
    const metadata = libraryItem.metadata as KmlLayerMetadata;
    if (!libraryItem.ownerId && !metadata.hierarchy) {
        return await loadKmlLayerHierarchy(Object.assign({}, libraryItem));
    } else {
        return await Promise.resolve();
    }
};

export const loadKmlLayerHierarchy = async (layerItem: KmlLayerTreeItem) => {
    const layerId = layerItem.id;

    if (layerId === undefined) {
        throw new Error('Layer ID is undefined');
    }

    const layerKey = layerItem.key;

    const result = layerId && (await getLayerConfig(layerId));
    const output = result as KmlLayerMetadata;
    const { hierarchy, styles } = output;

    let childId = 0;

    const getChildId = () => {
        childId++;
        return childId;
    };

    const hydratedLayerItem: KmlLayerTreeItem = parseKmlHierarchy(
        layerItem,
        hierarchy.children,
        layerKey,
        layerId,
        styles,
        getChildId
    );
    hydratedLayerItem.metadata = output;
    return hydratedLayerItem;
};

export const getConfigDownloadUrl = async (layerId: number): Promise<{ url: string }> => {
    const result = await endpoints.layerTiles.configUrl.get({
        templateValues: { layerId },
    });
    const output = await result.json();
    return output;
};

export const fetchLayerConfigFromStorage = async (url: string): Promise<LayerConfigBlobDto> => {
    const response = await fetch(url);
    return await response.json();
};

export const transformLayerStyles = (
    styleCollection: StyleCollectionDto
): Record<string, object> => {
    const { styles, styleIds } = styleCollection;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    return Object.keys(styleIds).reduce((output: any, key) => {
        output[key] = {};
        const styleMappings = styleIds[key];

        Object.keys(styleMappings).forEach((styleKey) => {
            const styleIdByName = kmlStyleTypes[Number(styleKey)];
            const styleMappingId = styleMappings[styleKey];
            const style = styles[styleMappingId];
            output[key][styleIdByName] = style;
        });

        return output;
    }, {});
};

export const getLayerConfig = async (
    layerId: number,
    dateString?: string
): Promise<object | null> => {
    await waitUntilGenerated(layerId);
    const layerDetails = await getLayerDetails(layerId);

    if (layerDetails.layerHierarchyVersion === 1) {
        return await getLayerConfigV1(layerId, layerDetails);
    } else {
        return await getLayerConfigV0(layerId, dateString);
    }
};

export const getLayerConfigLegacy = async (
    layerId: number,
    dateString?: string
): Promise<object | null> => {
    await waitUntilGeneratedLegacy(layerId);
    const layerDetails = await getLayerDetails(layerId);

    if (layerDetails.layerHierarchyVersion === 1) {
        return await getLayerConfigV1(layerId, layerDetails);
    } else {
        return await getLayerConfigV0(layerId, dateString);
    }
};

async function getLayerConfigV1(layerId: number, layerDetails: LayerDetails) {
    const { url: downloadUrl } = await getConfigDownloadUrl(layerId);
    const { styles, hasGroundOverlayGeometries, tiles, hierarchy, size, hasExternalResources } =
        await fetchLayerConfigFromStorage(downloadUrl);

    if (!hierarchy) {
        throw new Error('Layer config not found');
    }

    const simplifiedStyles = transformLayerStyles(styles);

    const batchedTile =
        hasGroundOverlayGeometries === false && MIN_SIZE_TO_BATCH <= size / 1048576
            ? { l0x0y0: true }
            : null;

    return {
        hierarchy,
        sceneLayers: layerDetails.sceneLayers,
        tiles: batchedTile ?? tiles,
        styles: simplifiedStyles,
        layerInfo: {
            hasExternalResources,
        },
    };
}

async function getLayerConfigV0(layerId: number, dateString?: string) {
    const response = await endpoints.layerTiles.configurations.get({
        queryParameters: {
            layerIds: layerId,
            modifiedDate: dateString || '',
        },
    });
    return (await response.json())[layerId];
}

export const getKmlLayerNodeBounds = (libraryItem: KmlLayerTreeItem) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    const metadata = libraryItem.metadata as any;
    if (metadata) {
        const bounds = (metadata.hierarchy && metadata.hierarchy.bounds) ?? metadata.bounds;
        if (bounds) return createExtentFromKmlBounds(bounds);
    }
};

const getLibraryItemsCheckedKeys = (libraryItem: LibraryLayerTreeItem): string[] => {
    const checkedKeys: string[] = [];
    if (!libraryItem.children?.length && libraryItem.checked) {
        checkedKeys.push(libraryItem.key);
    } else {
        libraryItem.children?.map((childItem) =>
            checkedKeys.push(...getLibraryItemsCheckedKeys(childItem))
        );
    }
    return checkedKeys;
};

export const createKmlLayerSaveState = (libraryItem: LibraryLayerTreeItem) => {
    const checkedKeys = getLibraryItemsCheckedKeys(libraryItem);
    return {
        active: checkedKeys.length > 0,
        treeState: checkedKeys.length ? JSON.stringify(checkedKeys) : '',
    } as LibraryKmlLayerSaveState;
};

export const searchKmlLayerById = (libraryItems: LibraryLayerTreeItem[], layerId: string) => {
    return libraryItems.some(({ itemType, key }) => {
        const layerIdFromKey = key.split('--')[1];
        return (
            (itemType === 'KmlLayer' || itemType === 'Layer') &&
            layerIdFromKey === layerId.toString()
        );
    });
};
