import Camera from '@arcgis/core/Camera';
import Point from '@arcgis/core/geometry/Point';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import { isEmpty } from 'lodash';

export interface CameraSettings {
    position: { latitude: number; longitude: number; z: number };
    tilt: number;
    heading: number;
}

export const parseCameraSettings = (cameraSettings: string) => {
    const [latitude, longitude, range, heading, tilt] = cameraSettings.split(',').map(parseFloat);
    const camera = new Camera({
        position: new Point({
            latitude: latitude,
            longitude: longitude,
            z: range,
        }),
        tilt: tilt,
        heading: heading,
    });
    return { target: camera };
};

export const flyToCameraSettings = (view: SceneView | MapView, cameraSettings: CameraSettings) => {
    const camera = new Camera({
        position: new Point(cameraSettings.position),
        tilt: cameraSettings.tilt,
        heading: cameraSettings.heading,
    });
    view.goTo({ target: camera });
};

export const serializeCameraSettings = (camera: __esri.Camera | CameraSettings) => {
    if (isEmpty(camera)) return '';
    return (
        `${camera.position.latitude},` +
        `${camera.position.longitude},` +
        `${camera.position.z},` +
        `${camera.heading},` +
        `${camera.tilt}`
    );
};
