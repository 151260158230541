import { useEffect, useRef, useState } from 'react';
import { Modal, Typography } from '@jll/react-ui-components';
import OktaAuth from '@okta/okta-auth-js';

import { setTokens } from 'utils/apiClient/ApiEndpoint';

interface AuthSessionRefreshModalProps {
    authClient: OktaAuth;
    open: boolean;
    setClose: () => void;
}

const AuthSessionRefreshModal = ({ authClient, open, setClose }: AuthSessionRefreshModalProps) => {
    const [sessionRefreshTab, setSessionRefreshTab] = useState<Window | null>(null);
    const messageListenerRef = useRef<((event: MessageEvent) => void) | null>(null);
    const [isSessionRefreshing, setIsSessionRefreshing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (!sessionRefreshTab) {
            return;
        }

        const messageListener = async (event: MessageEvent) => {
            if (event.source !== sessionRefreshTab || event.data !== 'session-refresh') {
                return;
            }

            await setTokens(authClient);
            setClose();
            setIsSessionRefreshing(false);
        };

        messageListenerRef.current = messageListener;
        window.addEventListener('message', messageListener);

        return () => {
            if (messageListenerRef.current) {
                window.removeEventListener('message', messageListenerRef.current);
            }
        };
    }, [sessionRefreshTab, authClient, setClose]);

    return (
        <Modal
            destroyOnClose
            open={open}
            title='Session Expired'
            confirmLoading={isSessionRefreshing}
            okText='Refresh Session'
            onCancel={() => {
                setClose();
            }}
            afterClose={() => {
                setIsSessionRefreshing(false);
                setErrorMessage(null);
            }}
            onOk={() => {
                setIsSessionRefreshing(true);
                const authRoute = '/session-refresh';
                const refreshSessionTab = window.open(authRoute, '_blank');

                if (!refreshSessionTab) {
                    setErrorMessage(
                        'Popup window was blocked. Please enable popups for this site.'
                    );
                    setIsSessionRefreshing(false);
                    return;
                }
                setSessionRefreshTab(refreshSessionTab);
                setErrorMessage(null);
            }}
        >
            <Typography.Text>
                Your session has expired. Click the button below to refresh your session.
            </Typography.Text>
            <br />
            {errorMessage && <Typography.Text type='danger'>{errorMessage}</Typography.Text>}
        </Modal>
    );
};

export default AuthSessionRefreshModal;
