import LineOfSightAnalysisObserver from '@arcgis/core/analysis/LineOfSightAnalysisObserver';
import LineOfSightAnalysisTarget from '@arcgis/core/analysis/LineOfSightAnalysisTarget';

import LibraryLayerTreeItem, {
    LibraryLayerTreeItemWithMetadata,
    WidgetLayerTreeItem,
} from '../types/Layers/LibraryLayerTreeItem';
import {
    LOSLayerMetadata,
    WidgetLayerMetadata,
    WidgetLayerSaveState,
    WidgetTypes,
} from '../types/Layers/WidgetLayerMetadata';
import { nanoid } from '../utils/idUtils';

export const createLOSLayerLibraryItem = (
    observer: LineOfSightAnalysisObserver,
    targets: LineOfSightAnalysisObserver[]
): WidgetLayerTreeItem => {
    const key = `WidgetLayer--${nanoid()}`;
    const metadata: WidgetLayerMetadata = {
        type: 'LOS',
        settings: {
            observer,
            targets,
        } as LOSLayerMetadata,
    };
    return {
        id: 0,
        title: 'LOS Result',
        itemType: 'WidgetLayer',
        key,
        metadata,
        checked: true,
        active: true,
        legendEnabled: false,
    } as WidgetLayerTreeItem;
};

export const createWidgetLayerSaveState = (
    libraryItem: LibraryLayerTreeItemWithMetadata
): WidgetLayerSaveState => {
    const { checked, title } = libraryItem;
    const metadata = libraryItem.metadata as WidgetLayerMetadata;
    return {
        title: title ?? '',
        checked: !!checked,
        settings: metadata,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const handleLOSWidgetState = (settings: any) => {
    const metadata = settings.settings;
    const observerData = metadata.observer;
    const targetsData = metadata.targets;
    const observer = new LineOfSightAnalysisObserver({
        ...observerData,
    });
    const targets = // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
        targetsData.map((target: any) => {
            return new LineOfSightAnalysisTarget({
                ...target,
            });
        });
    return {
        type: 'LOS',
        settings: {
            observer: observer,
            targets: targets,
        } as LOSLayerMetadata,
    } as WidgetLayerMetadata;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const deserializeWidgetSettings = (settings: any) => {
    const widgetType = settings?.type as WidgetTypes;
    switch (widgetType) {
        case 'LOS':
            return handleLOSWidgetState(settings as LOSLayerMetadata);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createWidgetLayerFromSavedState = (layer: any): LibraryLayerTreeItem => {
    const itemType = 'WidgetLayer';
    const { guid, name, settings } = layer;
    const key = guid ?? `${itemType}--${nanoid()}`;
    const metadata = deserializeWidgetSettings(settings);
    return {
        id: 0,
        itemType,
        key,
        title: name,
        metadata,
        checked: layer.active,
        legendEnabled: false,
    };
};
