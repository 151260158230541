import LibraryLayerTreeItem from 'types/Layers/LibraryLayerTreeItem';
import { nanoid } from 'utils/idUtils';
import { WebsiteOverlayMetadata } from '../types/Layers/WebsiteOverlayMetadata';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createWebsiteOverlayLibraryItem = (layer: any) => {
    const key = layer.guid ?? `Overlay--${nanoid()}`;
    const metadata = layer.settings
        ? { ...JSON.parse(layer.settings), id: key, title: layer.name }
        : { ...layer.metadata, id: key, title: layer.name };

    return {
        key,
        id: layer?.id ?? 0,
        title: layer.name,
        itemType: 'Overlay',
        isLeaf: true,
        metadata,
        checked: layer?.active,
    } as LibraryLayerTreeItem;
};

export const getOverlayProperties = (
    overlay: WebsiteOverlayMetadata,
    canvasWidth: number,
    canvasHeight: number
) => {
    const { isFullScreen, relativeHeight, height, relativeWidth, width } = overlay;
    const heightValue = isFullScreen
        ? 100
        : relativeHeight
        ? relativeHeight * 100
        : height
        ? (height / canvasHeight) * 100
        : 1;
    const widthValue = isFullScreen
        ? 100
        : relativeWidth
        ? relativeWidth * 100
        : width
        ? (width / canvasWidth) * 100
        : 1;
    const topValue = overlay.isFullScreen
        ? 0
        : overlay.relativeScreenY
        ? `${(overlay.relativeScreenY ?? 0.5) * 100}%`
        : overlay.posY;
    const leftValue = overlay.isFullScreen
        ? 0
        : overlay.relativeScreenX
        ? `${(overlay.relativeScreenX ?? 0.5) * 100}%`
        : overlay.posX;

    return {
        top: topValue,
        left: leftValue,
        height: `${heightValue}%`,
        width: `${widthValue}%`,
    };
};
