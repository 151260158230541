import { isEmpty } from 'lodash';

import {
    Slide,
    SlideFolder,
    SlideFolderSaveState,
    SlideImageType,
    SlideItem,
    SlideItemSaveState,
    SlideItemSaveStateBase,
    SlideSettings,
    SlideState,
} from 'components/Slides/SlideTypes';
import { SlideSaveState } from 'components/Slides/SlideTypes';
import { SLIDE_FOLDER_TYPE, SLIDE_TYPE } from 'constants/layer.constants';
import { cloudinary } from 'utils/cloudinaryUtils';
import { takeScreenshot } from 'utils/esri/screenshotUtils';
import { dataUrlToBlob, simpleDownload } from 'utils/fileUtils';
import { nanoid } from 'utils/idUtils';
import { uploadToCloudinary } from 'utils/slideUtils';
import { LegendState } from '../store/featuresSlice';

export const defaultSlideSettings: SlideSettings = {
    showSlideName: true,
};

export const getLegendVisibility = (legend?: boolean | LegendState): boolean => {
    return legend
        ? typeof legend === 'boolean'
            ? legend
            : (legend as LegendState).visible
        : false;
};

export const getSlideImageType = (state: SlideState, useFallback = false) => {
    if (isEmpty(state?.layers)) return SlideImageType.Default;

    const overlays = state.layers?.filter(function (layer) {
        if (!layer.type) return;
        return ['Image', 'Overlay'].includes(layer.type);
    });

    if (!overlays || isEmpty(overlays)) return SlideImageType.Default;

    const fullScreenMatch =
        overlays.find((item) => item.isFullScreen) ?? (useFallback && overlays[0]);

    if (fullScreenMatch) {
        return fullScreenMatch.type === 'Image'
            ? SlideImageType.ImageOverlay
            : SlideImageType.WebsiteOverlay;
    }

    return SlideImageType.Default;
};

export const loadSlideItemsFromSaveState = (items: SlideItemSaveState[]): SlideItem[] => {
    return items.map((item) => {
        if (item.slideItemType === 'Folder') {
            return createSlideFolderFromSaveState(item as SlideFolderSaveState);
        }
        return createSlideFromSaveState(item as SlideSaveState);
    });
};

const createSlideFromSaveState = (item: SlideSaveState): Slide => {
    const state = JSON.parse(item.state) as SlideState;
    return {
        cloudinaryImageId: item.cloudinaryImageId,
        id: item.id,
        image: item.image,
        name: item.name,
        folderId: item.folderId,
        hidden: item.hidden,
        slideItemType: 'Slide',
        key: nanoid(),
        active: false,
        imageType: getSlideImageType(state, !item.image),
        state: state,
    };
};

const createSlideFolderFromSaveState = (item: SlideFolderSaveState): SlideFolder => {
    return {
        ...item,
        // TODO: manually assign fields.
        slideItemType: 'Folder',
        key: nanoid(),
        children:
            item.children?.map((slideItem: SlideItemSaveState) =>
                createSlideFromSaveState(slideItem as SlideSaveState)
            ) || [],
    };
};

export const createSlideItemSaveState = (slideItem: SlideItem[]): SlideItemSaveStateBase[] => {
    return slideItem.map((item, index) => {
        if (item.slideItemType === 'Folder') {
            return createSlideFolderSaveState(item as SlideFolder, index);
        }
        return createSlideSaveState(item as Slide, index);
    });
};

const createSlideSaveState = (slide: Slide, sequence: number): SlideSaveState => {
    return {
        $type: SLIDE_TYPE,
        id: slide.id || 0,
        image: slide.image,
        cloudinaryImageId: slide.cloudinaryImageId,
        name: slide.name || '',
        sequence,
        active: false,
        hidden: slide.hidden,
        state: JSON.stringify(slide.state),
        slideItemType: 'Slide',
    };
};

const createSlideFolderSaveState = (
    slideFolder: SlideFolder,
    sequence: number
): SlideFolderSaveState => {
    return {
        $type: SLIDE_FOLDER_TYPE,
        id: slideFolder.id || 0,
        expanded: slideFolder.expanded,
        name: slideFolder.name || '',
        sequence,
        slideItemType: 'Folder',
        children: slideFolder.children.map((childSlide: SlideItem, index) =>
            createSlideSaveState(childSlide as Slide, index)
        ),
    };
};

export const takeScreenShot = async () => {
    const { dataUrl } = await takeScreenshot();
    const blob = dataUrlToBlob(dataUrl);
    return blob && (await uploadToCloudinary(blob));
};

export const exportSlideImage = async (slide: Slide) => {
    const imageUrl = cloudinary.image(slide.cloudinaryImageId).quality(100).format('jpg').toURL();
    simpleDownload(imageUrl, `${slide.name || 'Untitled Slide'}.jpg`);
};
