import { nanoid } from 'utils/idUtils';
import LibraryLayerTreeItem from '../types/Layers/LibraryLayerTreeItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createFolderItem = (layer?: any): LibraryLayerTreeItem => {
    return {
        key: layer?.guid ?? `Folder--${nanoid()}`,
        title: layer?.title ?? layer?.name ?? 'Untitled Folder',
        itemType: 'Folder',
        checked: layer?.active,
        legendEnabled: false,
    } as LibraryLayerTreeItem;
};

export const updateOrderChild = (
    layer: LibraryLayerTreeItem,
    childKey: string,
    newIndex: number
) => {
    if (layer.children) {
        const childIndex = layer.children.findIndex((child) => child.key === childKey);
        if (childIndex >= 0) {
            const updatedChildren = [...layer.children];
            const [child] = updatedChildren.splice(childIndex, 1);
            updatedChildren.splice(newIndex, 0, child);
            return {
                ...layer,
                children: updatedChildren,
            } as LibraryLayerTreeItem;
        }
    }
};

export interface PositionPath {
    parent?: LibraryLayerTreeItem;
    folderKey?: string;
    item: LibraryLayerTreeItem;
}

export const searchItemByPosition = (
    items: LibraryLayerTreeItem[],
    pos: string
): PositionPath | undefined => {
    const indices = pos.split('-').map((i) => Number(i));
    indices.shift();
    return searchFolderAndParentByKey(items, indices);
};

export const searchFolderAndParentByKey = (
    items: LibraryLayerTreeItem[],
    indices: number[],
    parent?: LibraryLayerTreeItem,
    folderKey?: string
): PositionPath | undefined => {
    if (indices.length === 1) {
        const item = items[indices[0]];
        return {
            parent: parent,
            item: item,
            folderKey: folderKey,
        };
    } else {
        parent = items[indices[0]];
        const children = parent?.children;
        if (children !== undefined) {
            indices.shift();
            if (!folderKey && parent.itemType == 'Folder') folderKey = parent.key;
            if (parent.itemType == 'Folder') parent = undefined;
            return searchFolderAndParentByKey(children, indices, parent, folderKey);
        } else {
            return undefined;
        }
    }
};

export type Direction = 'BOTTOM-TO-TOP' | 'TOP-TO-BOTTOM' | 'SAME';

export const findDragItemDirection = (nodePos: string, draggedNodePos: string): Direction => {
    const nodePosArr = nodePos.split('-').map(Number);
    const draggedNodePosArr = draggedNodePos.split('-').map(Number);

    for (let i = 0; i < nodePosArr.length; i++) {
        if (nodePosArr[i] < draggedNodePosArr[i]) {
            return 'BOTTOM-TO-TOP';
        } else if (nodePosArr[i] > draggedNodePosArr[i]) {
            return 'TOP-TO-BOTTOM';
        }
    }
    return 'SAME';
};
