import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { App as AntdApp, message } from '@jll/react-ui-components';
import AppRoutes from 'AppRoutes';

import { AuthProvider } from 'providers/AuthProvider';
import LDProvider from 'providers/LDProvider';
import { store } from 'store';
import ThemeProvider from 'themes/ThemeProvider';
import gqlClient from './gql-client';

import 'normalize.css';
import './index.scss';

const MESSAGE_CONFIG = { duration: 2, maxCount: 1, top: 72 };
message.config(MESSAGE_CONFIG);

const rootElement = document.getElementById('root');
if (rootElement == null) throw new Error('No root element');
const root = createRoot(rootElement);
root.render(
    <ThemeProvider>
        <AntdApp message={MESSAGE_CONFIG} style={{ height: '100%' }}>
            <ApolloProvider client={gqlClient}>
                <AuthProvider>
                    <Provider store={store}>
                        <LDProvider>
                            <BrowserRouter>
                                <AppRoutes />
                            </BrowserRouter>
                        </LDProvider>
                    </Provider>
                </AuthProvider>
            </ApolloProvider>
        </AntdApp>
    </ThemeProvider>
);
