import Color from '@arcgis/core/Color';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import Map from '@arcgis/core/Map';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import UniqueValue from '@arcgis/core/renderers/support/UniqueValue';
import UniqueValueClass from '@arcgis/core/renderers/support/UniqueValueClass';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import ColorVariable from '@arcgis/core/renderers/visualVariables/ColorVariable';
import SolidEdges3D from '@arcgis/core/symbols/edges/SolidEdges3D';
import FillSymbol3DLayer from '@arcgis/core/symbols/FillSymbol3DLayer';
import MeshSymbol3D from '@arcgis/core/symbols/MeshSymbol3D';
import PointSymbol3D from '@arcgis/core/symbols/PointSymbol3D';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import iconUrl from 'icons/custom/pin.svg?url';
import { find, isEqual } from 'lodash';

import LibraryLayerTreeItem from 'types/Layers/LibraryLayerTreeItem';
import { PropertyResultItem } from 'types/Search/PropertySearchResultProps';
import { nanoid } from 'utils/idUtils';
import { defaultPinColor, defaultPinHeight, defaultPinSize } from './highlightSetHelper';

export const blackbirdPolygonColor = [277, 6, 19, 1];
const defaultSymbolColor = [0, 0, 0, 1];
const defaultFloorColor = [225, 225, 225, 0.7];
const missingPolygonIcon = '/src/res/icons/missing-polygon-white.png';
export const polygonLayerKey = 'UnpinnedController--00';
export const DEFAULT_CALLOUT_COLOR = 'white';

export const createMeshSymbol3D = (polygonColor?: number[] | string | Color) => {
    polygonColor = polygonColor ?? blackbirdPolygonColor;
    return new MeshSymbol3D({
        symbolLayers: [
            new FillSymbol3DLayer({
                material: {
                    color: polygonColor,
                },
                edges: new SolidEdges3D({
                    color: polygonColor,
                    size: 0.5,
                }),
            }),
        ],
    });
};

export const createPoint3dSymbol = (imageCanvas = iconUrl, pinSize = defaultPinSize) => {
    return new PointSymbol3D({
        symbolLayers: [
            {
                type: 'icon',
                size: `${pinSize}px`,
                resource: { href: imageCanvas },
            },
        ],
    });
};

export const createPoint3dSymbolCallout = (
    imageCanvas = iconUrl,
    pinHeight = defaultPinHeight,
    pinSize = defaultPinSize,
    calloutColor = DEFAULT_CALLOUT_COLOR
) => {
    return new PointSymbol3D({
        symbolLayers: [
            {
                type: 'icon',
                size: `${pinSize}px`,
                resource: { href: imageCanvas },
            },
        ],
        verticalOffset: {
            minWorldLength: 0,
            maxWorldLength: pinHeight,
            screenLength: pinHeight,
        },
        callout: {
            type: 'line',
            color: calloutColor,
            size: 0.5,
            border: {
                color: calloutColor,
            },
        },
    });
};

export const addBuildingPin = (
    view: SceneView | MapView,
    center: { latitude: number; longitude: number }
) => {
    removeBuildingPin(view);
    const graphic = createPoint3DSymbolGraphic(center.latitude, center.longitude, undefined, {
        id: 'property-pin',
    });
    view.graphics.add(graphic);
};

const removeBuildingPin = (view: SceneView | MapView) => {
    const graphic = view.graphics.find((graphic) => {
        return graphic.attributes['id'] == 'property-pin';
    });
    if (graphic) view.graphics.add(graphic);
};

export const createPoint3DSymbolGraphic = (
    latitude: number,
    longitude: number,
    href?: string,
    attributes?: Record<string, number | string | null>
) => {
    const center = new Point({
        latitude,
        longitude,
    });
    const symbol = new PointSymbol3D({
        symbolLayers: [
            {
                type: 'icon',
                size: '40px',
                resource: { href: href ?? `${iconUrl}` },
            },
        ],
        verticalOffset: {
            minWorldLength: 0,
            maxWorldLength: defaultPinHeight,
            screenLength: defaultPinHeight,
        },
        callout: {
            type: 'line',
            color: defaultPinColor,
            size: 0.5,
            border: {
                color: defaultPinColor,
            },
        },
    });
    return new Graphic({
        geometry: center,
        attributes,
        symbol: symbol,
    });
};

export const createLayerSimpleRenderer = (
    visualVariables: ColorVariable[],
    polygonColor?: number[]
) => {
    return new SimpleRenderer({
        symbol: createMeshSymbol3D(polygonColor),
        visualVariables: visualVariables,
    });
};

export const createUniqueValueInfos = (
    values: string[],
    polygonColor?: number[] | string | Color
) => {
    const polygonSymbol = createMeshSymbol3D(polygonColor);
    return values.map((item) => {
        return new UniqueValueInfo({
            symbol: polygonSymbol,
            value: item,
        });
    });
};

export const createUniqueValueGroup = (classes: UniqueValueClass[]) => {
    return new UniqueValueGroup({
        classes,
    });
};

export const createUniqueValueClass = (
    values: string[] | null | undefined,
    symbol: MeshSymbol3D | PointSymbol3D
) => {
    return new UniqueValueClass({
        symbol,
        values: values?.map((value) => new UniqueValue({ value: value })),
    });
};

export const createMultipleUniqueValueClass = (
    values: UniqueValue[],
    symbol: MeshSymbol3D | PointSymbol3D
) => {
    return new UniqueValueClass({
        symbol,
        values,
    });
};

export const createUniqueValueRenderer = (
    uniqueValueInfos: UniqueValueInfo[],
    symbolColor = defaultSymbolColor
) => {
    return new UniqueValueRenderer({
        field: 'PropId',
        defaultSymbol: createMeshSymbol3D(symbolColor),
        uniqueValueInfos: uniqueValueInfos,
    });
};

export const createUniqueValueGroupsRenderer = (
    uniqueValueGroup: UniqueValueGroup[],
    type?: string,
    defaultColor: number[] | Color = blackbirdPolygonColor
) => {
    return new UniqueValueRenderer({
        field: 'PropId',
        defaultSymbol:
            type === 'point-3d' ? createPoint3dSymbolCallout() : createMeshSymbol3D(defaultColor),
        uniqueValueGroups: uniqueValueGroup,
    });
};

export const createFloorsUniqueValueGroupsRenderer = (
    uniqueValueGroups: UniqueValueGroup[],
    floorsColor?: number[] | Color | string
) => {
    return new UniqueValueRenderer({
        field: 'PropId',
        field2: 'StoryNum',
        fieldDelimiter: ':',
        defaultSymbol: createMeshSymbol3D(floorsColor ?? defaultFloorColor),
        uniqueValueGroups: uniqueValueGroups,
    });
};

export const createPolygonRenderer = (
    values: string[],
    polygonColor?: number[] | string | Color
) => {
    return createUniqueValueRenderer(createUniqueValueInfos(values, polygonColor));
};

export const createFloorsRenderer = (values: string[], polygonColor?: number[]) => {
    return new UniqueValueRenderer({
        field: 'PropId',
        field2: 'StoryNum',
        fieldDelimiter: ':',
        defaultSymbol: createMeshSymbol3D(defaultSymbolColor),
        uniqueValueInfos: createUniqueValueInfos(values, polygonColor),
    });
};

export const createLibraryPolygonLayer = (sceneLayerId: string, key?: string): SceneLayer => {
    return new SceneLayer({
        portalItem: {
            id: sceneLayerId,
        },
        popupEnabled: false,
        id: key ?? nanoid(),
        outFields: ['*'],
        renderer: createUniqueValueGroupsRenderer([] as UniqueValueGroup[]),
    });
};

export const addUniqueValueGroupToRenderer = (
    renderer: UniqueValueRenderer,
    symbol: MeshSymbol3D | PointSymbol3D,
    key: string,
    values: string[],
    defaultColor: number[] | Color = blackbirdPolygonColor
) => {
    const uniqueGroup = createUniqueValueGroup([
        createUniqueValueClass(values, symbol),
    ] as UniqueValueClass[]);
    uniqueGroup.set('key', key);
    const groups = renderer.uniqueValueGroups.concat(uniqueGroup);
    return createUniqueValueGroupsRenderer(groups as UniqueValueGroup[], symbol.type, defaultColor);
};

export const addUniqueValuesToGroup = (
    renderer: UniqueValueRenderer,
    key: string,
    values: string[]
) => {
    const group = find(renderer.uniqueValueGroups, { key: key }) as UniqueValueGroup;
    if (group) {
        const newValues = values.map((value) => new UniqueValue({ value: value }));

        group.classes[0].values = [...group.classes[0].values, ...newValues];
        const groups = renderer.uniqueValueGroups as UniqueValueGroup[];
        return createUniqueValueGroupsRenderer(groups, renderer.defaultSymbol.type);
    }
};

export const removeUniqueValuesToGroup = (
    renderer: UniqueValueRenderer,
    key: string,
    values: string[]
) => {
    const group = find(renderer.uniqueValueGroups, { key: key }) as UniqueValueGroup;
    if (group) {
        const valuesToRemove = values.map((value) => new UniqueValue({ value: value }));

        group.classes[0].values = group.classes[0].values.filter((item) => {
            return !valuesToRemove.some((valueToRemove) => isEqual(item, valueToRemove));
        });
        const groups = renderer.uniqueValueGroups as UniqueValueGroup[];
        return createUniqueValueGroupsRenderer(groups);
    }
};

export const createMarketSceneLayer = (sceneLayerId: string): SceneLayer => {
    return new SceneLayer({
        portalItem: {
            id: sceneLayerId,
        },
        popupEnabled: false,
        id: `floors_${sceneLayerId}`,
        outFields: ['*'],
        renderer: createFloorsUniqueValueGroupsRenderer(
            [] as UniqueValueGroup[],
            blackbirdPolygonColor
        ),
    });
};

export const createHighlightPinsLayer = () => {
    return new FeatureLayer({
        source: [],
        objectIdField: 'OBJECTID',
        id: `highlight-pins`,
        fields: [
            {
                name: 'OBJECTID',
                type: 'oid',
            },
            {
                name: 'PropId',
                type: 'string',
            },
            {
                name: 'height',
                type: 'double',
            },
        ],
        elevationInfo: {
            mode: 'absolute-height',
            featureExpressionInfo: {
                expression: '$feature.height',
            },
            unit: 'meters',
        },
        geometryType: 'point',
        renderer: new UniqueValueRenderer({
            field: 'PropId',
            defaultSymbol: createPoint3dSymbolCallout(),
            uniqueValueGroups: [] as UniqueValueGroup[],
        }),
        legendEnabled: false,
    });
};

export const createMissingPolygonsLayer = (properties: PropertyResultItem[]) => {
    const graphics = [] as Graphic[];
    properties.forEach((item: PropertyResultItem) => {
        const geoLocation = item.addresses[0].geolocation;
        if (geoLocation) {
            const attributes = { PropId: item.id };
            graphics.push(
                createPoint3DSymbolGraphic(
                    geoLocation.latitude,
                    geoLocation.longitude,
                    missingPolygonIcon,
                    attributes
                )
            );
        }
    });
    return new GraphicsLayer({
        graphics,
        visible: true,
        minScale: 250000,
    });
};

export const findPolygonLayer = (map: Map) => {
    return map?.findLayerById('UnpinnedController');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createAllPropertiesItem = (layer?: any) => {
    const key = layer?.guid ?? polygonLayerKey;
    return {
        id: 0,
        key,
        itemType: 'UnpinnedController',
        title: layer?.name ?? 'Marketsphere Properties',
        isLeaf: true,
        checked: layer?.active,
        active: layer?.active,
        legendEnabled: true,
    } as LibraryLayerTreeItem;
};
