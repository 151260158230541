import Extent from '@arcgis/core/geometry/Extent';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';

import { OSM_OVERPASS_API_ENDPOINT } from 'workers/overpass';

// Define an interface for the building element
export interface BuildingElement {
    id: string;
    type: 'way' | 'relation';
    height?: number;
    center: {
        lat: number;
        lon: number;
    };
    tags: {
        height?: string;
        name?: string;
        'building:levels'?: string;
    };
    bounds?: { minlat: number; minlon: number; maxlat: number; maxlon: number };
}

export const queryOverpassAPI = async (osmIds: number[], outputFields: string) => {
    // Create a string of OSM IDs separated by commas
    const osmIdString = osmIds.join(',');

    // Define the Overpass query with the OSM IDs as a parameter
    const overpassQuery = `[out:json];(rel(id:${osmIdString}); way(id:${osmIdString}););out ${outputFields};`;

    try {
        // Send an HTTP GET request to the Overpass API endpoint with the query
        const response = await fetch(`${OSM_OVERPASS_API_ENDPOINT}`, {
            method: 'POST',
            body: `data=${encodeURIComponent(overpassQuery)}`,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Extract the building geometries from the response
        const buildings: BuildingElement[] = data.elements.filter(
            (element: BuildingElement) => element.type === 'way' || element.type === 'relation'
        );
        return buildings;
    } catch (error) {
        console.error('Error retrieving building geometries:', error);
    }
};

export const getBuildingExtentByOsmId = async (osmId: number, zmax?: number, zmin?: number) => {
    const buildings = await queryOverpassAPI([osmId], 'geom');
    if (buildings?.length) {
        const bounds = buildings[0]?.bounds;
        if (bounds) {
            const extent = new Extent({
                spatialReference: SpatialReference.WGS84,
                xmin: bounds.minlon,
                ymin: bounds.minlat,
                xmax: bounds.maxlon,
                ymax: bounds.maxlat,
                zmin: zmin,
                zmax: zmax,
            });
            return webMercatorUtils.geographicToWebMercator(extent) as Extent;
        }
    }
};

export const getBuildingCenterByOsmId = async (osmIds: number[]) => {
    const buildings = await queryOverpassAPI(osmIds, 'center');
    if (buildings?.length) {
        return buildings
            .map((building) => [building.center.lon, building.center.lat])
            .filter((pos) => !isNaN(pos[0]) && !isNaN(pos[1]));
    }
};
